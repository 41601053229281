<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
            :key="componentKey"
            :mode="mode"
            @back="backToList"
            @save="saveData"
            :triggerSave.sync="triggerSave"
            :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import formEdit from './add'
import gql from "graphql-tag";
import ApiRepository from "@/core/ApiRepository";
import Cryptojs from 'crypto-js'
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Create new admin",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      time_zone_local : moment.tz.guess(true),
      triggerSave: false
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'edit' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Edit admin information' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_VIEW_ADMIN);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.redirectEdit();
    },
    UpdateActive(key){
      // console.log("key",key)
      var uid = this.$route.params.id;
      this.$commonFuction.is_loading(true);
      if(key == 'lock'){
        this.active_check = false
      }else{
        this.active_check = true
      }

      let variables = {
        'data_update': {
          'active': this.active_check
        },
        'where_key': {
          'id': {'_eq': this.$route.params.id}
        }
      };
      var that = this;
      this.$apollo.mutate({
        mutation: DataServices.updateData('users'),
        variables: variables
      }).then(async (res) => {
        console.log('Success',res);
        if(key == 'lock'){
          var query_t =  `mutation {
            delete_jwt_token(where: { uid: {_eq: "${uid}"}}) {
              returning {
                id
              }
            }
          }`
          await that.$apollo.mutate({
            mutation: gql(query_t),
          })
        }
        that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
      })
      this.$commonFuction.is_loading(false);
      // console.log("this.$route.params.id",this.$route.params.id)
    },
    async saveData(params) {
      try{
        var dataRequested = this.$commonFuction.convertJsonObject(params);
        //console.log("dataRequesteddataRequested:",dataRequested)
        if (!this.$commonFuction.isEmpty(dataRequested.password)){
          dataRequested.password = (Cryptojs.SHA256(dataRequested.password )).toString()
        }
        const headers= {
          "Authorization": "Bearer " + (await this.$AuthenticationApp.getToken()),
        };
        var list_action_detail = await ApiRepository.post('/_api/users/save_admin', dataRequested, {headers});
        console.log("list_action_detail", list_action_detail);
        //this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Saved data successfully', life: 3000 });
        //window.location.href = '/admin/list-view_admin';
        this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
        this.redirectEdit();
      }
      catch (err) {
        console.log('errerrerr:',err)
        const error = err?.response?.data ?? null;
        if (error.message == "ACCOUNT_IS_EXISTED_ERR"){
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Username already exists', life: 3000 });
        }
        /*else if (error.message == "EMAIL_IS_EXISTED_ERR"){
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Email đã tồn tại', life: 3000 });
        }*/
        else if (error.message == "MISMATCH_PARAMS_ERR"){
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Missing required fields', life: 3000});
        }
        else if (error.message == "SERVER_ERR"){
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Exception error', life: 3000});
        }
        else this.$toast.add({severity: 'error', summary: 'Error', detail: 'Save data failed', life: 3000});
        this.$commonFuction.is_loading(false);
        //this.componentKey++;

      }
    },
    /*
    async saveData(params) {
      if (params !== undefined && Object.entries(params).length > 0) {

        var objects = {
          //'phone': params.user.phone,
          'fullname': params.fullname,
          //'plain_fullname': this.$commonFuction.SkipVN(params.user.fullname),
          //'email': params.email,
         // 'is_admin': true,
          'role':'admin_child'
        }
        /!*var group_user = {
          'group_id': params.user.group_id,
        }*!/
        var that = this;
        if (this.mode === 'add') {

          var dataInsert = this.$commonFuction.convertJsonObject(objects);
          let accountsInsert = {
            'account': params.email,
            'type': 'EMAIL',
            'role': 'admin_child',
            'password' : this.$commonFuction.hashPasswordAdmin(params.password),
          }
          dataInsert.accounts = {data: this.$commonFuction.convertJsonObject(accountsInsert)};
          //dataInsert.group_users = {data: this.$commonFuction.convertJsonObject(group_user)};

          await that.$apollo
              .mutate({
                //mutation: gql(queryUsers),
                mutation: DataServices.insertData('users'),
                variables: {
                  objects: dataInsert
                }
              })
              .then( async (res)  => {

                if (res.data[Object.keys(res.data)].affected_rows) {

                  var returning = res.data[Object.keys(res.data)].returning[0];
                  var list_property = params?.list_property ?? [];
                  var list_inser = [];
                  for (let i = 0; i < list_property.length; i++) {
                    var itemp = list_property[i];
                    var itemI = {
                      property_id:itemp?.id ?? null,
                      uid:itemp?.id ?? null,
                    }
                    list_inser.push(itemI)
                  }
                  if (list_inser.length>0){
                    await that.$apollo
                        .mutate({
                          //mutation: gql(queryUsers),
                          mutation: DataServices.insertData('role_admin_property'),
                          variables: {
                            objects: list_inser
                          }
                        })
                        .then( (res)  => {


                          console.log('Thành công');
                          console.log(res);//
                          this.$commonFuction.is_loading(false);
                          that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công', life: 3000});
                          //this.$router.push({path: '/admin/list-view_admin'});


                        }).catch(async error => {
                          console.log('error', error);
                          that.$toast.add({
                            severity: 'error',
                            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                            life: 3000
                          });
                          this.$commonFuction.is_loading(false);
                          // this.summitEvent = false;
                        });
                  }


                }
              }).catch(async error => {
                console.log('error', error);
                that.$toast.add({
                  severity: 'error',
                  summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
                });
                this.$commonFuction.is_loading(false);
                // this.summitEvent = false;
              });
        }
        else  {
          //trường hợp ko chỉnh sửa email
          var uid = params.user.user_id
          var accountUpdate = {
            'account': params.user.email,
            // 'account': params.user.email,
          }
          if(params.user.password){
            accountUpdate.password = this.$commonFuction.hashPasswordAdmin(params.user.password)
          }
          var queryUpdate = `
                  mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input) {
                    update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
                      affected_rows
                      returning {
                        id
                      }
                    }
                    update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
                      affected_rows
                      returning {
                        id
                      }
                    }


                  }
            `;

          let variables = {
            users_object: objects,
            accounts_object: accountUpdate,
           // group_user_object: group_user,
          };
          that.$apollo.mutate({
            mutation: gql(queryUpdate),
            variables: variables
          }).then(async (res) => {
            console.log(res)
            that.$commonFuction.is_loading(false);
            var router_back = {path: '/admin/list-view_admin'};
            if (!that.$commonFuction.isEmpty(router_back)){
              router_back.query = { page_transaction: that.page_transaction.toString() };
            }
            that.$router.push(router_back);
            that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
          })
        }
      }
    },*/
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-view_admin'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('view_admin'),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      console.log("datadata:",data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        var dataImage =  await this.$CoreService.getFileMapFile([this.$constants.TYPE_IMAGE.IMG_AVARTAR_SERVICE,this.$constants.TYPE_IMAGE.IMG_NORMAL_SERVICE], [this.models.id]);
        this.models["list_file_edit"] = dataImage.listData
        /*

        var {listData} = await this.$CoreService.getMultipleFile(
            [
              this.$constants.TYPE_IMAGE.USER
            ],this.models.id,0);
        if (listData.length > 0){
           console.log("image avater",listData)
          for (let i = 0; i < listData.length; i++) {
            const ele = listData[i];
            if(ele.relationship_files.length > 0){
              if(ele.relationship_files[0].type == this.$constants.TYPE_IMAGE.USER){
                this.models.item_avatar = {
                  id : ele.id,
                  url : ele.url,
                  file_name : ele.file_name,
                }
              }
            }
          }
        }*/
      }
      this.componentKey++;
    },
  },
  apollo: {

  }
}
</script>

<style lang="scss">
.manager-title-custom {
  color: #1D3118;
  font-size: 24px !important;
  /* margin-bottom: 16px; */
  padding-bottom: 20px;
  /* border-bottom: 1px solid #EEEEEE; */
  font-weight: 700;
  margin-left: 20px;
}
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.p-toolbar-group-right {
  flex-basis: 30%;
}
.p-button-warning.p-button {
  margin: 0 10px;
}
</style>