<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="col-6">
        <!--        <div class="p-card-field">
                  <label class="p-col-fixed txt-right txt-required">Property</label>
                  <div class="p-col">
                    <v-select :clearable="false" title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="obj_data.property_id" :options="listProperty" :reduce="property_item => property_item.id" label="name"  >
                      <span slot="no-options" @click="$refs.select.open = false">
                        Empty data
                      </span>
                    </v-select>
                    <input class="input_tmp_validator" id="property">
                  </div>
                </div>-->

        <!--        <div class="p-card-field">
                  <label class="p-col-fixed txt-right txt-required">Property ID</label>
                  <div class="p-col">
                    <InputText id="code" type="text" class="" v-model="obj_data.code"  placeholder="Input" :disabled="mode == 'edit'"/>
                  </div>
                </div>-->
        <div class="p-card-field" v-if="mode == 'edit'">
          <label class="p-col-fixed txt-right ">Permission</label>
          <div class="p-col">
            <InputText disabled="disabled" type="text" class="" v-model="obj_data.name_group" placeholder="" />
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Username</label>
          <div class="p-col">
            <InputText  :disabled="mode == 'edit'" maxlength="255" id="email" type="text" class="" v-model="obj_data.email" placeholder="Input" />
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Fullname</label>
          <div class="p-col">
            <InputText maxlength="50" id="fullname" type="text" class="" v-model="obj_data.fullname"
              placeholder="Input" />
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Password</label>
          <div class="p-col">
            <div class="box-password-style" >
              <InputText maxlength="50" id="password" :type="passwordType" class="" v-model="obj_data.password"
                         placeholder="Input" />
              <i  v-if="passwordType === 'text'" class="material-icons lg-icon-password" @click="switchVisibility">visibility</i>
              <i  v-else class="material-icons lg-icon-password" @click="switchVisibility">visibility_off</i>
            </div>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Re-enter password</label>
          <div class="p-col">
            <div class="box-password-style" >
            <InputText maxlength="50" id="Re_enter_password" :type="passwordReType" class="" v-model="obj_data.re_password"
              placeholder="Input" />
              <i  v-if="passwordReType === 'text'" class="material-icons lg-icon-password" @click="switchVisibility($event,'re')">visibility</i>
              <i  v-else class="material-icons lg-icon-password" @click="switchVisibility($event,'re')">visibility_off</i>
            </div>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property</label>
          <div class="p-col">
            <v-select :clearable="false" title="Property" class="" @search="SearchProperty" placeholder="Choose"
              v-model="obj_data.property_id" :options="listProperty" :reduce="property_item => property_item.id"
              label="name">
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="property">
          </div>
        </div>
        <div class="p-card-field">
          <!--          <label class="p-col-fixed txt-right ">Price</label>-->
          <div class="p-col">
            <div class="style_btn_add_type">
              <div class="style_btn_add" @click="save_item_list"><img src="/assets/images/icon/ic_add_24dp.svg" /></div>
              <!--              <Button  label="+"  @click="save_item_list" />-->
            </div>
          </div>
        </div>


      </div>
      <div class="col-12">
        <div class="p-card-field">
          <div class="p-col">
            <BaseDatatable v-if="dataModelType" :key="componentKeyType" :tableData="listDataType" :model="dataModelType"
              :actions="getRowActions()" v-on:action-click="onActionClicked"></BaseDatatable>
          </div>
        </div>
      </div>

      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button :label="$constants.TEXT_BUTTON.BACK" @click="backToList()" class="p-button-outlined" id="back" />
          <Button v-if="mode == 'edit'" :label="$constants.TEXT_BUTTON.EDIT" @click="saveData()"
            class="main-style-button" />
          <Button v-else :label="$constants.TEXT_BUTTON.ADD" @click="saveData()" class="main-style-button" />
        </template>
      </Toolbar>
      <Dialog header="Delete" :visible.sync="showDeleteDialog" :contentStyle="{ overflow: 'visible' }" :modal="true">
        Are you sure you want to delete?
        <template #footer>
          <Button label="Delete" @click="deleteData()" icon="pi pi-check"
            class="p-button-danger  btn-confirm-delete-dialog" />
          <Button label="Cancel" @click="showDeleteDialog = false" icon="pi pi-times" class="p-button-warning" />
        </template>
      </Dialog>
    </div>
  </form>
</template>
<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
//import CkditorCore from '../CkditorCore';
import BaseDatatable from './list_property'
export default {
  components: {
    // CkditorCore,
    BaseDatatable,
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      passwordType: 'password',
      passwordReType: 'password',

      key_type_id: 1,

      dataModelMadatory: null,
      componentKeyMadatory: 1,
      listDataMadatory: [],

      listProperty: [],
      listPropertyAll: [],
      mapPropertyAll: {},
      listPropertySelected: [],

      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,

      mapServicePrice: {},
      dataModelType: null,
      componentKeyType: 1,
      listDataType: [],

      cke_key: 1,
      listLang: [],
      content_en: '',
      obj_data: {
        filename: "",
        prefix_phone: "",
      },
      comFilename: 1,
      list_phone_code: [
        {
          prefix_phone: "+84",
        },
        {
          prefix_phone: "+45",
        }
      ],
      dataBookingRound: [],
      selProperty: null,
      mapPropertyType: {},
      listPropertyType: [],
      listPropertyTypeAll: [],
      listDocsCate: [],
      listDocsCateAll: [],
      listCountry: [],
      listCountryAll: [],
      listState: [],
      listStateAll: [],
      listUser: [],
      listUserAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      time_zone_local: moment.tz.guess(true),
      componentKey: 0,
      list_file: [],
      list_id_file_delete: [],
      maxFileSize: 2097152 * 50, //100MB  2MB   /*104857600, // 100MB*/
      list_part: [
        {
          "uid": null,
          //"uid": "ee053528-31bb-4822-a620-c5664ecd1cd7",
          "part": "1",
          "order_booking": 1,
          "color": null
        },
        {
          "uid": null,
          //"uid": "79ed9b2a-ebfb-46d7-87d4-982e8cdb8f65",
          "part": "2",
          "order_booking": 2,
          "color": null
        },
        {
          "uid": null,
          //"uid": "321674aa-9161-4193-8422-5bd26a3224c2",
          "part": "3",
          "order_booking": 3,
          "color": null
        },
        {
          "uid": null,
          //"uid":  "75629674-e721-493c-81b0-8ba014ae2aa0",
          "part": "4",
          "order_booking": 4,
          "color": null
        },
        {
          "uid": null,
          //"uid": "d9d3a852-1932-43b6-a2b8-b3ac0e7257cf",
          "part": "5",
          "order_booking": 5,
          "color": null
        }
      ],
      list_madatory: [
        {
          "type_of_use_name": 'Property owner',
          "type_of_use": 1,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 0,
        },
        {
          "type_of_use_name": 'Five2One owner',
          "type_of_use": 2,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 1,
        },
        {
          "type_of_use_name": 'Borrower',
          "type_of_use": 3,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 2,
        },
        {
          "type_of_use_name": 'External renter',
          "type_of_use": 4,
          "auto_add_to_cart": false,
          "user_remove_it": false,
          "day_of_stay_more_than": 0,
          "index": 3,
        }
      ],
      index_edit: null,
    }
  },
  watch: {
    /* async "selProperty"() {
       if (!this.$commonFuction.isEmpty(this.selProperty)) {
         this.address = this.selProperty?.address;
         this.partArr = this.selProperty?.part;
         if (this.mode == 'add') {
           var arr = [];
           this.partArr.map(m => {
             arr.push({ord: m.order_booking, id: m.id});
           })
           arr.map(m=> {
             var tmp = [];
             for (let i =0; i < arr.length; i++) {
               let a = m.ord - i + arr.length;
               if (a > arr.length) {
                 a = a - arr.length;
               }
               tmp.push({ordinal: a, part_id: m.id, round: (i+1)});
             }
             this.dataBookingRound.push(tmp);
           })
         }
         this.componentKey++;
       }
     },
     "start_booking_date"() {
       if (this.partArr.length) {
         let days = 14 * this.partArr.length;
         this.end_booking_date = moment.tz(this.start_booking_date, this.time_zone_local).add(days, 'days').format("LL");
       }
     }*/
  },
  async mounted() {
    this.getListProperty();
    //this.getListCountry();
    //this.getListState();
    //this.getListUser();
    /*
    await this.getListDocsCate();*/
    console.log("this.model:", this.model)




    var dataLang = await this.$CoreService.getListLang({});
    var list_lang = dataLang.list
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("66666666666666666")
      this.obj_data = this.model;
      this.obj_data.email = this.obj_data.account;
      this.obj_data.name_group = (this.obj_data.name_group) ? this.obj_data.name_group : '';
      if (!this.$commonFuction.isEmpty(this.model?.list_file_edit)) {
        var list_file_edit = this.model?.list_file_edit
        for (let i = 0; i < list_file_edit.length; i++) {
          var item_file = list_file_edit[i]
          var obj = { id: item_file.id, 'name': '', 'file': '', 'objectURL': item_file.url, type: 'old_edit' }//type:'old_edit' nếu gặp type này ko update avatar
          this.list_file.push(obj)
        }
      }
      /* var list_part =this.model?.part ?? []
       for (let g = 0; g < list_part.length; g++) {
         var item_part = list_part[g];
         var order_booking = item_part?.order_booking-1
         var uid_part = item_part?.uid
         this.list_part[order_booking]["uid"] =uid_part;
       }*/

      /*
            this.obj_data.filename = this.model?.item_avatar?.file_name ?? ''
            if (!this.$commonFuction.isEmpty(this.model?.item_avatar)) {
              let url = this.model?.item_avatar?.url
              let obj = {'name': this.obj_data.filename, 'file': '', 'objectURL': url, type: 'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
              this.list_file.push(obj)
            }
            */
      /* if (!this.$commonFuction.isEmpty(this.obj_data.available_from)) {
         this.obj_data.available_from = moment.tz(this.obj_data.available_from, this.time_zone_local).format("LL");
       }
       if (!this.$commonFuction.isEmpty(this.obj_data.available_to)) {
         this.obj_data.available_to = moment.tz(this.obj_data.available_to, this.time_zone_local).format("LL");
       }*/
      /*
            var lang_ser_arr = this.model?.lang_arr ?? [];
            var mapLS ={};
            for (let h = 0; h < lang_ser_arr.length; h++) {
              var itemLS = lang_ser_arr[h];
              mapLS[itemLS.id_lang] = itemLS;
            }
      
            for (let i = 0; i < list_lang.length; i++) {
              var itemL = list_lang[i];
              var id_l = itemL.id
              list_lang[i].content = (!this.$commonFuction.isEmpty(mapLS[id_l])) ?  mapLS[id_l].description:''
            }
            this.listLang = list_lang*/

      //xử lý type
      var type_of_property_arr = this.model?.arr_role_admin_property ?? [];
      var list_type_convert = [];
      for (let g = 0; g < type_of_property_arr.length; g++) {
        var itemType = type_of_property_arr[g];
        var itemTypeConvert = {
          "id": itemType?.obj_property?.id ?? null,
          "name": itemType?.obj_property?.name ?? '',
        }
        list_type_convert.push(itemTypeConvert)
      }
      this.listDataType = list_type_convert;
      console.log("this.listDataType:", this.listDataType)
      this.loadTableType();

      //xứ lý mandatory
      /* var mandatory_arr = this.model?.mandatory_arr ?? [];
       var mapMan ={};
       for (let k = 0; k < mandatory_arr.length; k++) {
         var itemMan = mandatory_arr[k];
         mapMan[itemMan.type_user] = itemMan;
       }
       console.log("mapManmapMan:",mapMan)
       console.log("mandatory_arr:",mandatory_arr)
       for (let m = 0; m < this.list_madatory.length; m++) {
         var itemM = this.list_madatory[m];
         var type_of_use = itemM.type_of_use
         //console.log("type_of_use:",type_of_use)
         //console.log("type_of_use:",type_of_use)
         this.list_madatory[m].auto_add_to_cart = (!this.$commonFuction.isEmpty(mapMan[type_of_use])) ?  mapMan[type_of_use].auto_add_to_cart:false;
         this.list_madatory[m].user_remove_it = (!this.$commonFuction.isEmpty(mapMan[type_of_use])) ?  mapMan[type_of_use].user_remove_it:false;
         this.list_madatory[m].day_of_stay_more_than = (!this.$commonFuction.isEmpty(mapMan[type_of_use])) ?  mapMan[type_of_use].day_of_stay_more_than:false;
       }
 
       this.dataModelMadatory = DataServices.getModel('service_madatory');
       this.listDataMadatory = this.list_madatory
       console.log("this.listDataMadatory:",this.listDataMadatory)
       this.dataModelMadatory.processData(this.list_madatory)
       this.componentKeyMadatory++;*/
      this.SearchProperty(null, null)

      this.componentKey++;
    } else {
      for (let i = 0; i < list_lang.length; i++) {
        list_lang[i].content = '';
      }
      this.listLang = list_lang
      console.log("listLanglistLanglength:", this.listLang.length)
      //thêm mới
      this.obj_data = {
        /*name:'service for car tax',
        before_arrival_date:1,
        available_from:moment.tz().format("LL"),
        available_to:moment.tz().format("LL"),*/
        /* email:'tuyen11112',
         fullname:'ssss',
         password:'123456',
         re_password:'123456',
         type_of_price:'1',*/
        ordinal: 1,

      };
      //gán data
      //this.listDataType = [ { "id": "3ba3f00e-d926-437a-bde6-0ca41fcdcb2c", "type_name": "type2", "price_key": 2342, "stt": 1, "index": 0, "type_name_key": "type2", "price_key_tmp": 2342 } ];
      //this.loadTableType()

      /* this.dataModelMadatory = DataServices.getModel('service_madatory');
       this.listDataMadatory = this.list_madatory
       this.dataModelMadatory.processData(this.list_madatory)
       this.componentKeyMadatory++;*/

      this.dataModelType = DataServices.getModel('role_admin_property');
      console.log('this.dataModelType:', this.dataModelType)
      this.componentKeyType++;

      this.componentKey++;
    }
  },
  methods: {
    switchVisibility(event,type=null) {
      console.log("event:",event)
      console.log("typetype:",type)
      if (type=='re'){
        this.passwordReType = this.passwordReType === 'password' ? 'text' : 'password'
      }else {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
      }
    },
    async getListProperty() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListProperty(where_key);
      this.listProperty = list.list;
      this.listPropertyAll = list.list;
      this.mapPropertyAll = list.list_map;
    },
    async SearchProperty(search, loading) {
      console.log(loading);
      var list_data = []
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListProperty(where_key);
        // this.listProperty = list.list;
        list_data = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        //this.listProperty = this.listPropertyAll
        list_data = this.listPropertyAll
      }
      this.listProperty = this.getListPropertyNotin(list_data);
    },
    getListPropertyNotin(list_data) {
      var listSelected = this.listDataType;
      var list_id = [];
      console.log("listSelected:", listSelected)
      for (let i = 0; i < listSelected.length; i++) {
        var itemS = listSelected[i];
        list_id.push(itemS.id);
      }
      var list_convert = [];
      if (list_id.length > 0) {
        for (let j = 0; j < list_data.length; j++) {
          var itemC = list_data[j];
          var id_check = itemC.id
          if (list_id.indexOf(id_check) == -1) {
            list_convert.push(itemC)
          }
        }
      } else {
        list_convert = list_data
      }
      return list_convert;
    },
    DataOnChangeMadatory(data, f) {
      console.log("f.keyf.key:", f.key)
      console.log("datadatadatadata:", data)
      var index = data.index;
      this.listDataMadatory[index] = data;
      /*if(f.key == 'active'){

      }*/
    },
    async deleteData() {
      this.showDeleteDialog = false;
      var list_data = []
      for (let i = 0; i < this.listDataType.length; i++) {
        var item_data = this.listDataType[i];
        if (this.deleteId != item_data.id) {
          list_data.push(item_data);
        }
      }
      this.listDataType = list_data;
      this.loadTableType()
      this.SearchProperty(null, null)
    },
    async loadTableType() {
      var stt = 0;
      var listDataType = this.listDataType;
      //this.mapServicePrice = {};
      for (let i = 0; i < listDataType.length; i++) {
        stt++;
        //var itemData = listDataType[i];
        //var id = itemData?.id ?? null;
        listDataType[i].stt = stt;
        listDataType[i].index = i;
        //this.mapServicePrice[id] = itemData;
      }
      this.listDataType = listDataType
      console.log(" this.listDataType:", this.listDataType)
      this.dataModelType = DataServices.getModel('role_admin_property');
      //this.dataModelType.processData(listDataType)
      this.componentKeyType++;
    },
    async save_item_list() {
      if (this.$commonFuction.isEmpty(this.obj_data.property_id)) {
        alert("Please select property!");
        return false;
      }
      var itemType = this.mapPropertyAll[this.obj_data.property_id]
      console.log("itemTypeitemType:", itemType)
      var itemObject = {
        // stt:1,
        id: itemType?.id ?? null,
        name: itemType?.name ?? null,
      }
      this.listDataType.push(itemObject);
      /* if (!this.$commonFuction.isEmpty(this.index_edit)) {
         for (let i = 0; i < this.listDataType.length; i++) {
           var item_data = this.listDataType[i];
           var index = item_data.index
           if (type_id == item_data.id && index != this.index_edit ){
             alert("Service type of property already exists!");
             return false;
           }
         }
         this.listDataType[this.index_edit] = itemObject;
       }else {
         if (!this.$commonFuction.isEmpty(this.mapServicePrice[type_id])) {
           alert("Service type of property already exists!");
           return false;
         }
         this.listDataType.push(itemObject);
       }*/

      //var listDataType = this.listDataType;
      this.obj_data.property_id = null;
      this.loadTableType()
      this.SearchProperty(null, null)
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      console.log("action.nameaction.name:", action.name)
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          console.log("datadatadata:", data)
          /*this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }*/
          this.index_edit = data.data.index
          this.obj_data.type_id = data.data.id
          this.obj_data.price_select = data.data.price_key
          this.key_type_id++;
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          console.log("this.deleteId:", this.deleteId)
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    getRowActions() {
      var actions = {}
      var that = this;
      /*if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }*/
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        //if (that.role.edit) {
        // buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
        //}
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        //if (that.role.delete) {
        buttons.delete = { name: 'delete', icon: 'trash', key: 'id' }
        //}
        if (that.dataModelType.actions) {
          var modelaction = that.dataModelType.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    handleDataCkditor: function (e, index, key) {
      var dataContent = (e.length) ? e[0] : '';
      if (key == 'content') {
        //this.content = dataContent;
        this.listLang[index].content = dataContent;
      }
    }
    ,
    async onFileSelectFile(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;
      console.log("filesfiles:", files)
      console.log("files.length:", files.length)
      if (files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG']
        console.log("files.length1111111:", files.length)
        for (let i = 0; i < files.length; i++) {
          console.log("files.333333333333:", files.length)
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Only post formats are supported: jpeg, jpg, gif, png, svg");
            return;
          }
          //  document.getElementById('file_name').innerText = files[i].name;
          console.log("files[i].namefiles[i].name:", files[i].name)
          that.obj_data.filename = files[i].name;
          this.comFilename++;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          console.log('file.sizefile.sizefile.size:', file.size)
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            console.log("asdasdasfile:", file)
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Your image is larger 100MB");
          that.obj_data.filename = "";
          return;
        } else {
          // if (this.icon_service.length > 0 && this.icon_service[0].file === '') {
          //   this.imagesDeletes.push(this.icon_service[0])
          // }
          console.log("arrTemparrTemp:", arrTemp)
          //this.list_file = [];
          this.list_file = this.list_file.concat.apply(this.list_file, arrTemp);
        }
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}`);
    },
    async getListPropertyType() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListPropertyType(where_key);
      this.listPropertyType = list.list;
      this.listPropertyTypeAll = list.list;
      this.mapPropertyType = list.map_data;
    },
    async SearchPropertyType(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListPropertyType(where_key);
        this.listPropertyType = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listPropertyType = this.listPropertyTypeAll
      }
    },
    async getListCountry() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListCountry(where_key);
      this.listCountry = list.list;
      this.listCountryAll = list.list;
    },
    async SearchCountry(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListCountry(where_key);
        this.listCountry = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listCountry = this.listCountryAll
      }
    },
    async getListState() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListState(where_key);
      this.listState = list.list;
      this.listStateAll = list.list;
    },
    async SearchState(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListState(where_key);
        this.listState = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listState = this.listStateAll
      }
    },
    async getListUser() {
      var where_key = {
        deleted: { _eq: false },
        role: { _eq: this.$constants.ROLE_ACCOUNT.OWNER },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListUser(where_key);
      this.listUser = list.list;
      this.listUserAll = list.list;
    },
    async SearchUser(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.fullname = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListUser(where_key);
        this.listUser = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listUser = this.listUserAll
      }
    },

    async getListDocsCate() {
      var where_key = {
        deleted: { _eq: false },
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListDocsCate(where_key);
      this.listDocsCate = list.list;
      this.listDocsCateAll = list.list;
    },

    async SearchDocsCate(search, loading) {
      console.log(loading);
      if (search !== '' && search !== null) {
        var where_key = {
          deleted: { _eq: false },
        };
        if (!this.$commonFuction.isEmpty(search)) {
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListDocsCate(where_key);
        this.listDocsCate = list.list;
        //console.log("this.listProperty:",this.listProperty)
      } else {
        // cosnole.log("dasdasd")
        this.listDocsCate = this.listDocsCateAll
      }
    },

    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};

      let email = document.getElementById("email");
      if (this.$commonFuction.isEmpty(this.obj_data.email)) {
        email.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        email.setCustomValidity("");
      }

      let fullname = document.getElementById("fullname");
      if (this.$commonFuction.isEmpty(this.obj_data.fullname)) {
        fullname.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        fullname.setCustomValidity("");
      }
      console.log("this.formModethis.formMode:", this.mode)
      if (this.mode == 'add') {
        let password_add = document.getElementById("password");
        if (this.$commonFuction.isEmptyObject(this.obj_data.password)) {
          password_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        } else {
          if (this.obj_data.password.length < 6) {
            password_add.setCustomValidity("Please enter a password of at least 6 characters!");
          } else {
            //kiểm tra
            password_add.setCustomValidity("");
          }
        }
      }
      let Re_enter_password = document.getElementById("Re_enter_password");
      if (!this.$commonFuction.isEmptyObject(this.obj_data.password) || !this.$commonFuction.isEmptyObject(this.obj_data.re_password)) {
        //check password khớp
        console.log('this.obj_data.password:', this.obj_data.password)
        console.log('this.obj_data.re_password:', this.obj_data.re_password)
        if (this.obj_data.password != this.obj_data.re_password) {
          Re_enter_password.setCustomValidity("Passwords do not match!");
        } else {
          Re_enter_password.setCustomValidity("");
        }
      }
      let property_add = document.getElementById("property");
      if (this.listDataType.length == 0) {
        property_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        property_add.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
    async checkCode(code) {
      // if(this.email !== null && this.email !== ''){
      var where = {
        code: { '_eq': code },
        deleted: { '_eq': false },
        // type: {'_eq' : 'user'}
      }
      if (this.model !== undefined) where = Object.assign(where, { id: { '_neq': this.model.id } })
      var { data } = await this.$apollo.query({
        query: DataServices.getList('property', { "fields": "id, name,code" }),
        variables: {
          where_key: where
        },
        fetchPolicy: "network-only"
      })
      // console.log(data)
      return data && data[Object.keys(data)].length > 0
      //}
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      //console.log("this.from_to", this.from_to);
      //console.log("partArr", this.dataBookingRound);
      var params = this.obj_data
      params["list_property"] = this.listDataType
      /*
      params["list_type"] = this.listDataType
      params["list_madatory"] = this.listDataMadatory
      params["list_lang"] = this.listLang
      params["list_id_file_delete"] = this.list_id_file_delete*/
      console.log('saveData', params)
      this.$emit('save', params)
    },
    onFileClear(item, index) {
      console.log('itemitem:', item)
      var type = item?.type ?? null
      if (type == 'old_edit') {
        this.list_id_file_delete.push(item.id);
      }
      this.list_file.splice(index, 1);
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}

.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}

.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.p-multiselect-label-container {
  height: 40px;
}

.p-inputtext {
  margin: 0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.p-col-fixed {
  padding: 0px !important;
  margin: 0px 0px 0px 9px !important;
}

.input_text {
  width: 760px !important;
  height: 48px !important;
}

.txt-right {
  font-weight: bold;
}

.multiselect {
  width: 760px !important;
}

.layout-main {
  background: #00000029;
}

.vs__clear {
  display: none;
}

.input_width {
  width: 760px !important;
  height: 48px !important;
}

#distance {
  width: 1px !important;
  height: 48px !important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}

.manager-title {
  font-size: 24px !important;
}

.input-dif {
  margin-left: 5px !important;
  border-top: 0px;
}

.p-inputgroup-addon {
  height: 48px;
}

.txt-right {
  font-weight: bold;
}

.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}

label.p-col-fixed txt-right {
  margin-bottom: 0px !important;
}

div.p-card-field {
  padding: 0px !important;
}

.margin {
  margin-bottom: 12px !important;
}

.border_color {
  border: 1px solid #C8C8C8;
}

.text {
  font-weight: normal !important;
  margin-left: 15px !important;
}

.div_check {
  margin-top: 12px !important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
    width: 100% !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width {
    width: 100% !important;
  }

  .div-button {
    padding: 8px !important;
  }

  .p-inputgroup-addon {
    margin-right: 12px;
  }

  #distance {
    text-align: left !important;
    padding: 6.8px;
  }
}

.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}

.cus-text {
  margin-left: 2px;
  margin-top: 4px;
}

.style-box-phone {
  border: 1px solid #a6a6a6;
  width: 100%;
  display: flex;

  .p-dropdown {
    width: 100px;
    border: 0px;
    box-shadow: none !important;
  }

  .p-focus {
    border: 0px;
    box-shadow: none;
  }

  .p-dropdown:focus {
    border: 0px;
    box-shadow: none;
  }

  input {
    width: calc(100% - 100px);
    margin-left: 0px;
    border: 0px;
  }

  .p-inputtext:enabled:focus {
    border: 0px;
    box-shadow: none;
  }
}

.style_list_image {
  display: flex;
  flex-wrap: wrap;
  /* Cho phép các phần tử xuống dòng */
  gap: 10px;

  /* Khoảng cách giữa các phần tử */
  .box-image {
    width: 100px;
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;

    .style_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #D0D4D9;
      border-radius: 10px;
    }

    .button-clear {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1px;
      right: 8px;
    }
  }
}

.style_box_room {
  display: flex;
}

.style_box_lat_log {
  display: flex;

  .box_lat_log {
    width: 50%;
  }
}

.style_title_group {
  font: normal normal bold 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #666666;
  text-transform: uppercase;
}

.style_box_price {
  padding-left: 35px !important;
}

.style_rad_button {
  label {
    margin-bottom: 0px;
    margin-left: 15px;
  }
}

.style_btn_add_type {
  width: 64px;

  .style_btn_add {
    cursor: pointer;
    width: 64px;
    height: 48px;
  }
}
.box-password-style{
  width: 100%;
  position: relative;
  .lg-icon-password {
    margin-top: -30px;
    display: block;
    position: absolute;
    right: 10px;
    padding: auto 5px;
    &:hover {
      cursor: pointer;
    }
  }
}

</style>
